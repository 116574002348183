import {lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "./core/components/PrivateRoute";

// Admin
const Admin = lazy(() => import("./admin/pages/Admin"));
const Dashboard = lazy(() => import("./admin/pages/Dashboard"));
const ForgotPassword = lazy(() => import("./auth/pages/ForgotPassword"));
const ForgotPasswordSubmit = lazy(
    () => import("./auth/pages/ForgotPasswordSubmit")
);
const Login = lazy(() => import("./auth/pages/Login"));
// Core
const Forbidden = lazy(() => import("./core/pages/Forbidden"));
const NotFound = lazy(() => import("./core/pages/NotFound"));
const UnderConstructions = lazy(
    () => import("./core/pages/UnderConstructions")
);

const UserProfile = lazy(() => import("./admin/pages/UserProfile"));
const Profile = lazy(() => import("./admin/pages/Profile"));
const ProfileActivity = lazy(() => import("./admin/pages/ProfileActivity"));
const ProfileJoinTrip = lazy(() => import("./admin/pages/ProfileJoinTrip"));
const ProfileWaitingTrip = lazy(() => import("./admin/pages/ProfileWaitingTrip"));
const ProfileInformation = lazy(
    () => import("./admin/pages/ProfileInformation")
);
const ProfilePassword = lazy(() => import("./admin/pages/ProfilePassword"));

// Users
const FeedBackManagement = lazy(() => import("./Views/feedBack/pages/FeedBackManagement"));
const UserManagement = lazy(() => import("./Views/users/pages/UserManagement"));
const AdminManagement = lazy(() => import("./Views/admins/pages/AdminManagement"));
const TravelManagement = lazy(() => import("./Views/travels/pages/TravelManagement"));
const TravelChats = lazy(() => import("./Views/travels/pages/TravelChats"));
const StationManagement = lazy(() => import("./Views/stations/pages/StationManagement"));
const TravelView = lazy(() => import("./Views/travels/pages/TravelView"));
const DistributorManagement = lazy(() => import("./Views/distributors/pages/DistributorManagement"));
const TravelTicketManagement = lazy(() => import("./Views/travelTicket/pages/TravelTicketManagement"));
const RecommandationManagement = lazy(() => import("./Views/recommandations/pages/RecommandationManagement"));
const AdviceManagement = lazy(() => import("./Views/advices/pages/AdviceManagement"));
const TermManagement = lazy(() => import("./Views/terms/pages/TermManagement"));
const ContactManagement = lazy(() => import("./Views/contacts/pages/ContactManagement"));

const AppRoutes = () => {
    return (
        <Routes basename={process.env.PUBLIC_URL}>
            <Route path="/" element={<Login/>}/>
            <PrivateRoute path="admin" element={<Admin/>}>
                <PrivateRoute path="/" element={<Dashboard/>}/>
                <PrivateRoute path="user-management" element={<UserManagement/>}/>
                <PrivateRoute path="admin-management" element={<AdminManagement/>}/>
                <PrivateRoute path="station-management" element={<StationManagement/>}/>
                <PrivateRoute path="feedback-management" element={<FeedBackManagement/>}/>
                <PrivateRoute path="contact-management" element={<ContactManagement/>}/>
                <PrivateRoute path="travel-management" element={<TravelManagement/>}/>
                <PrivateRoute path="travel-management/:id" element={<TravelView/>}/>
                <PrivateRoute path="travel-management/chats/:id" element={<TravelChats/>}/>
                <PrivateRoute path="distributor-management" element={<DistributorManagement/>}/>
                <PrivateRoute path="travelTicket-management" element={<TravelTicketManagement/>}/>
                <PrivateRoute path="recommandation-management" element={<RecommandationManagement/>}/>
                <PrivateRoute path="advice-management" element={<AdviceManagement/>}/>
                <PrivateRoute path="term-management" element={<TermManagement/>}/>
                <PrivateRoute path="profile" element={<Profile/>}>
                    <PrivateRoute path="" element={<ProfileInformation/>}/>
                    <PrivateRoute path="trips" element={<ProfileActivity/>}/>
                    <PrivateRoute path="trips-joined" element={<ProfileJoinTrip/>}/>
                    <PrivateRoute path="trips-pending" element={<ProfileWaitingTrip/>}/>
                    <PrivateRoute path="password" element={<ProfilePassword/>}/>
                </PrivateRoute>
                <PrivateRoute path="profile/account/:id" element={<UserProfile/>}>
                    <PrivateRoute path="" element={< ProfileInformation/>}/>
                    <PrivateRoute path="trips" element={<ProfileActivity/>}/>
                    <PrivateRoute path="trips-joined" element={<ProfileJoinTrip/>}/>
                    <PrivateRoute path="trips-pending" element={<ProfileWaitingTrip/>}/>
                    <PrivateRoute path="password" element={<ProfilePassword/>}/>
                </PrivateRoute>
            </PrivateRoute>
            <Route path="forgot-password" element={<ForgotPassword/>}/>
            <Route path="forgot-password-submit" element={<ForgotPasswordSubmit/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="under-construction" element={<UnderConstructions/>}/>
            <Route path="403" element={<Forbidden/>}/>
            <Route path="404" element={<NotFound/>}/>
            <Route
                path="*"
                element={<Navigate to={`/${process.env.PUBLIC_URL}/404`} replace/>}
            />
        </Routes>
    );
};

export default AppRoutes;
