import { Options, PaginationOptions, Contact } from '../utils/DataSchemas'
import request from '../utils/request'

export default class ContactService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (paginationWithSearchOption: PaginationOptions) => {
    let query = ''

    if (paginationWithSearchOption.offset !== null) {
      query
        ? (query += `&offset=${paginationWithSearchOption.offset}`)
        : (query += `?offset=${paginationWithSearchOption.offset}`)
    }

    if (paginationWithSearchOption.limit) {
      query
        ? (query += `&limit=${paginationWithSearchOption.limit}`)
        : (query += `?limit=${paginationWithSearchOption.limit}`)
    }
    if (paginationWithSearchOption.search) {
      query
        ? (query += `&search=${paginationWithSearchOption.search}`)
        : (query += `?search=${paginationWithSearchOption.search}`)
    }


    return request('GET', `contacts/${query}`, this.options)
  }


  async create(data: Contact) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `contacts/`, requestOptions)
  }



/*
  async getById(id: string) {
    return request('GET', `contacts/${id}`, this.options)
  }


  async update(id: string, data: Contact) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PATCH', `contacts/${id}`, requestOptions)
  }*/
}
