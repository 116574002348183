import React, { createContext, useContext } from "react";
import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";
import { useUserInfo } from "../hooks/useUserInfo";
import { UserInfo } from "../types/userInfo";
import { login as reduceLogin, logout as reduceLogout }  from "../../redux/actions/auth"
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";

interface AuthContextInterface {
  hasRole: (roles?: string[]) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  userInfo?: UserInfo;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggingIn, login } = useLogin();
  const { isLoggingOut, logout } = useLogout();
  
  const {data} = useUserInfo();
  const hasRole = (roles?: string[]) => {
    if (!roles || roles.length === 0) {
      return true;
    }
    if (!data) {
      return false;
    }
    return true
  };

  const handleLogin = async (email: string, password: string) => {
    return login({ email, password })
      .then((data: any) => {
        dispatch(reduceLogin(data))
        return data.access_token;
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleLogout = async () => {
    return logout()
      .then(() => {
        dispatch(reduceLogout())
        navigate(`/${process.env.PUBLIC_URL}/login`, { replace: true })
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        userInfo: data as any,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
