import { AuthActionTypeEnum } from "../../helpers/enums/statActionTypeEnums";
import { Auth } from '../types/auth'
import { User } from "../../Views/users/types/user";


export const login = (data: Auth) => {
    return {
        type: AuthActionTypeEnum.LOGIN,
        data: data
    };
};

export const updateCurrentUser = (data: User) => {
    return {
        type: AuthActionTypeEnum.UPDATE_SELECT_USER,
        selectUser: data
    };
};

export const logout = () => {
    return {
        type: AuthActionTypeEnum.LOGOUT,
    };
};