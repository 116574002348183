import { Options, Distributor, DistributorPaginationOptions } from '../utils/DataSchemas'
import request from '../utils/request'

export default class DistributorService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (paginationWithSearchOption: DistributorPaginationOptions) => {
    let query = ''

    if (paginationWithSearchOption.offset !== null) {
      query
        ? (query += `&offset=${paginationWithSearchOption.offset}`)
        : (query += `?offset=${paginationWithSearchOption.offset}`)
    }

    if (paginationWithSearchOption.limit) {
      query
        ? (query += `&limit=${paginationWithSearchOption.limit}`)
        : (query += `?limit=${paginationWithSearchOption.limit}`)
    }
    if (paginationWithSearchOption.search) {
      query
        ? (query += `&search=${paginationWithSearchOption.search}`)
        : (query += `?search=${paginationWithSearchOption.search}`)
    }
    if (paginationWithSearchOption.isOnBanner) {
      query
        ? (query += `&isOnBanner=${paginationWithSearchOption.isOnBanner}`)
        : (query += `?isOnBanner=${paginationWithSearchOption.isOnBanner}`)
    }

    return request('GET', `distributors/${query}`, this.options)
  }

  async create(data: Distributor) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }

    return request('POST', `distributors/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `distributors/${id}`, this.options)
  }


  async update(id: string, data: Distributor) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PATCH', `distributors/${id}`, requestOptions)
  }

  async delete(id: string) {
    return request('DELETE', `distributors/${id}`, this.options)
  }

  deleteMany = async (ids: string[]) => {
    const requestOptions: Options = {
      ...this.options,
      data: ids
    }

    return request('DELETE', 'distributors/multiple', requestOptions)
  }
}
