import { Contact } from '../../types/contact'
import { ActionType } from '../../../../helpers/types/ActionType';
import { ContactActionTypeEnum } from '../../helpers/enums/actionTypeEnums';
import { updatedArray, arrayReduceMany } from '../../../../helpers/functions';

export interface State {
  value: number,
  data: Contact[],
  total: number
}

const initialState: State = {
  value: 0,
  data:[],
  total: 0
}

export const GetData = (contact: Contact | any) => {

  const dataInfo = {
    ...contact,
    key: contact.id??contact?._id,
    id: contact.id??contact?._id,
    nbTravels: contact?.nbTravels||0
 
  }
  return dataInfo
}
const DataListFormater = (data: Contact[] | any) => {
  const results = [{}]
  results.pop()
  if (data) {
    data.map((dataInfo: any) => {
      results.push(DataFormat(dataInfo))
    })
  }
  return results
}

const DataFormat = (contactData: Contact | any) => {
  const data = GetData(contactData)
  return {
    ...data
  }
}

const ContactReducer = (state = initialState, action: ActionType<Contact>) => {
  switch (action.type) {
    case ContactActionTypeEnum.List: {
      return {
        ...state,
        data: DataListFormater(action.results),
        total: action.total
      }
    };

   /* case ContactActionTypeEnum.Update:
      const updatedData = DataFormat(action.data);
      return {
        ...state,
        data: updatedArray(state.data, updatedData),
        showModal: false
      };

    case ContactActionTypeEnum.Delete:
      const ids = action.ids||[];
      return {
        ...state,
        data: arrayReduceMany(state.data, ids),
        total: state.total - ids?.length,
        showModal: false
      };

    case ContactActionTypeEnum.Add:
      let dataList = !state.data ? [] : state.data;
      dataList.unshift(DataFormat(action.data) as Contact);
      return {
        ...state,
        data: dataList,
        total: state.total + 1,
        showModal: false
      };*/

    default:

      return state;
  }
};

export default ContactReducer;