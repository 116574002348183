import { useMutation } from "react-query";
import { Client } from "@sharebahn/general-backend-sdk";

const client = new Client();
client.configure();

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<string> => {
  return await client.login({ email, password })
};

export function useLogin() {
  const { isLoading, mutateAsync } = useMutation(login);

  return { isLoggingIn: isLoading, login: mutateAsync };
}
