import {Travel} from '../../types/travel'
import {ActionType} from '../../../../helpers/types/ActionType';
import {TravelActionTypeEnum} from '../../helpers/enums/actionTypeEnums';
import {updatedArray, arrayReduceMany} from '../../../../helpers/functions';
import {User} from '../../../users/types/user';

export interface State {
    value: number,
    data: Travel[],
    total: number,
    dataSpecific:Travel[],
    totalSpecific:number
}

const initialState: State = {
    value: 0,
    data: [],
    total: 0,
    dataSpecific:[],
    totalSpecific:0
}

const getUser = (user: User | null) => {
    let result = ""
    if (user) {
        result = `${user?.fullname}`
    }
    return result
}

export const GetData = (travel: Travel | any) => {
    const dataInfo = {
        ...travel,
        travelCreator: getUser(travel?.creator),
        key: travel?.id ?? travel?._id,
        id: travel?.id ?? travel?._id,
        departStation: {
            name: travel.departure?.name,
            city: travel.departure?.city || ""
        },
        arrivalStation:{
            name: travel.arrival?.name,
            city: travel.arrival?.city || ""
        },
        departId: travel.departure._id,
        arrivalId: travel.arrival._id,
    }
    return dataInfo
}
export const DataListFormater = (data: Travel[] | any) => {
    const results = [{}]
    results.pop()
    if (data) {
        data.map((dataInfo: any) => {
            results.push(DataFormat(dataInfo))
        })
    }
    return results
}

const DataFormat = (travelData: Travel | any) => {
    const data = GetData(travelData)
    return {
        ...data
    }
}

const TravelReducer = (state = initialState, action: ActionType<Travel>) => {
    switch (action.type) {
        case TravelActionTypeEnum.List: {
            return {
                ...state,
                data: DataListFormater(action.results),
                total: action.total
            }
        }

        case TravelActionTypeEnum.ListSpecific: {
            return {
                ...state,
                dataSpecific: DataListFormater(action.results),
                totalSpecific: action.total
            }
        }

        case TravelActionTypeEnum.Update:
            const updatedData = DataFormat(action.data);
            return {
                ...state,
                data: updatedArray(state.data, updatedData),
                showModal: false
            };

        case TravelActionTypeEnum.Delete:
            const ids = action.ids || [];
            return {
                ...state,
                data: arrayReduceMany(state.data, ids),
                total: state.total - ids?.length,
                showModal: false
            };

        case TravelActionTypeEnum.Add:
            let dataList = !state.data ? [] : state.data;
            dataList.unshift(DataFormat(action.data) as Travel);
            return {
                ...state,
                data: dataList,
                total: state.total + 1,
                showModal: false
            };

        default:

            return state;
    }
};

export default TravelReducer;