import { User } from '../../types/user'
import { ActionType } from '../../../../helpers/types/ActionType';
import { UserActionTypeEnum } from '../../helpers/enums/actionTypeEnums';
import { updatedArray, arrayReduceMany } from '../../../../helpers/functions';

export interface State {
  value: number,
  data: User[],
  total: number
}

const initialState: State = {
  value: 0,
  data:[],
  total: 0
}

export const GetData = (user: User | any) => {

  const dataInfo = {
    ...user,
    key: user.id??user?._id,
    id: user.id??user?._id,
    nbTravels: user?.nbTravels||0
 
  }
  return dataInfo
}
const DataListFormater = (data: User[] | any) => {
  const results = [{}]
  results.pop()
  if (data) {
    data.map((dataInfo: any) => {
      results.push(DataFormat(dataInfo))
    })
  }
  return results
}

const DataFormat = (userData: User | any) => {
  const data = GetData(userData)
  return {
    ...data
  }
}

const UserReducer = (state = initialState, action: ActionType<User>) => {
  switch (action.type) {
    case UserActionTypeEnum.List: {
      return {
        ...state,
        data: DataListFormater(action.results),
        total: action.total
      }
    }

    case UserActionTypeEnum.Update:
      const updatedData = DataFormat(action.data);
      return {
        ...state,
        data: updatedArray(state.data, updatedData),
        showModal: false
      };

    case UserActionTypeEnum.Delete:
      const ids = action.ids||[];
      return {
        ...state,
        data: arrayReduceMany(state.data, ids),
        total: state.total - ids?.length,
        showModal: false
      };

    case UserActionTypeEnum.Add:
      let dataList = !state.data ? [] : state.data;
      dataList.unshift(DataFormat(action.data) as User);
      return {
        ...state,
        data: dataList,
        total: state.total + 1,
        showModal: false
      };

    default:

      return state;
  }
};

export default UserReducer;