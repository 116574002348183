import { Options, LoginRequest } from '../utils/DataSchemas'
import request from '../utils/request'

export default class AuthService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  login = async (req: LoginRequest) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/login`, requestOptions)
  }

  register = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/register`, requestOptions)
  }

  
  registerGoogle = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/registerGoogle`, requestOptions)
  }

  registerFacebook = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/registerFacebook`, requestOptions)
  }

  recoverPassword = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/password/recover`, requestOptions)
  }


  changeEmail = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/change/email`, requestOptions)
  }

  resetPassword = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/password/reset`, requestOptions)
  }

  accountConfirmation = async (req: any) => {
    const requestOptions: Options = {
      ...this.options,
      data: req
    }
    return request('POST', `auth/account/confirmation`, requestOptions)
  }

 

  getMe = async () => {
    return request('GET', `auth/me`, this.options)
  }
}
