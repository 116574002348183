import { StatActionTypeEnum } from "../../helpers/enums/authActionTypeEnums copy";
import { AuthActionTypeEnum } from "../../helpers/enums/statActionTypeEnums";

export interface State {
    activityData: any|null,
}

const initialState: State = {
  activityData: []
}

  
const StatReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case StatActionTypeEnum.ACITIVITY_TRIP_CREATED: {
          return {
            ...state,
            activityData: action.data
          }
        }
      
        default:
          return state;
    }
};

export default StatReducer;