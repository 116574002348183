import { Station } from '../../types/station'
import { ActionType } from '../../../../helpers/types/ActionType';
import { StationActionTypeEnum } from '../../helpers/enums/actionTypeEnums';
import { updatedArray, arrayReduceMany } from '../../../../helpers/functions';

export interface State {
  value: number,
  data: Station[],
  total: number
}

const initialState: State = {
  value: 0,
  data:[],
  total: 0
}

export const GetData = (station: Station | any) => {

  const dataInfo = {
    key: station.id??station?._id,
    id: station.id??station?._id,
    name:station?.name||'',
    city:station?.city||'',
    zipcode:station?.zipcode||'',
    lattitude:station?.lattitude||'',
    longitude:station?.longitude||'',
    ...station
  }
  return dataInfo
}
const DataListFormater = (data: Station[] | any) => {
  const results = [{}]
  results.pop()
  if (data) {
    data.map((dataInfo: any) => {
      results.push(DataFormat(dataInfo))
    })
  }
  return results
}

const DataFormat = (stationData: Station | any) => {
  const data = GetData(stationData)
  return {
    ...data
  }
}

const StationReducer = (state = initialState, action: ActionType<Station>) => {
  switch (action.type) {
    case StationActionTypeEnum.List: {
      return {
        ...state,
        data: DataListFormater(action.results),
        total: action.total
      }
    }
    case StationActionTypeEnum.Update:
      const updatedData = DataFormat(action.data);
      return {
        ...state,
        data: updatedArray(state.data, updatedData),
        showModal: false
      };

    case StationActionTypeEnum.Delete:
      const ids = action.ids||[];
      return {
        ...state,
        data: arrayReduceMany(state.data, ids),
        total: state.total - ids?.length,
        showModal: false
      };

    case StationActionTypeEnum.Add:
      let dataList = !state.data ? [] : state.data;
      dataList.unshift(DataFormat(action.data) as Station);
      return {
        ...state,
        data: dataList,
        total: state.total + 1,
        showModal: false
      };

    default:

      return state;
  }
};

export default StationReducer;