// ** Redux Imports
import { combineReducers } from "redux"
import { connectRouter } from 'connected-react-router'

// ** Reducers Imports
import UserReducer from "../../Views/users/store/reducer"

import AuthReducer from "./auth"
import AdminReducer from "../../Views/admins/store/reducer"
import TravelReducer from "../../Views/travels/store/reducer"
import StationReducer from "../../Views/stations/store/reducer"
import DistributorReducer from "../../Views/distributors/store/reducer"
import TravelTicketReducer from "../../Views/travelTicket/store/reducer"
import RecommandationReducer from "../../Views/recommandations/store/reducer"
import AdviceReducer from "../../Views/advices/store/reducer"
import FeedBackReducer from "../../Views/feedBack/store/reducer"
import TermReducer from "../../Views/terms/store/reducer"
import ContactReducer from "../../Views/contacts/store/reducer"
import StatReducer from "./stat"

const rootReducer = (history:any) =>combineReducers({
  router: connectRouter(history),
  users:UserReducer,
  auth:AuthReducer,
  admins:AdminReducer,
  travels:TravelReducer,
  stations: StationReducer,
  distributors: DistributorReducer,
  travelTickets: TravelTicketReducer,
  recommandations:RecommandationReducer,
  advices : AdviceReducer,
  feedBacks: FeedBackReducer,
  terms: TermReducer,
  stat: StatReducer,
  contacts:ContactReducer
})

export default rootReducer
