import { configureStore, createStore } from '@reduxjs/toolkit'
import rootReducer from '../reducers/reducers'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();

const persistConfig = {
  key: 'root-shareBan',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history))

/*
export const store = configureStore({
  reducer: rootReducer,

})*/

export const store = createStore(
  persistedReducer,//createRootReducer(history), // root reducer with router state
);


export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

