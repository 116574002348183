import AuthService from './services/auth'
import UserService from './services/user'
import UploadService from './services/upload'
import TestimonialService from './services/testimonial'
import FaqService from './services/faq'
import OfferService from './services/offer'
import StationService from './services/station'
import AlerteService from './services/alerte'
import TravelService from './services/travel'
import PublicationService from './services/publication'
import JoinTravelRequestService from './services/joinTravelRequest'
import DistributorService from './services/distributor'
import TravelTicketService from './services/travelTicket'
import AdviceService from './services/advice'
import RecommandationService from './services/recommandation'
import FeedBackService from './services/feedBack'
import ContactService from './services/contact'
import TermService from './services/term'

// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import API from './config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
    options: dataSchemas.Options
    private authService!: AuthService
    private userService!: UserService
    private uploadService!: UploadService
    private testimonialService!: TestimonialService
    private faqService!: FaqService
    private offerService!: OfferService
    private stationService!: StationService
    private alerteService!: AlerteService
    private travelService!: TravelService
    private publicationService!: PublicationService
    private joinTravelRequestService!: JoinTravelRequestService
    private distributorService!: DistributorService
    private travelTicketService!: TravelTicketService
    private adviceService!: AdviceService
    private recommandationService!: RecommandationService
    private feedBackService!: FeedBackService
    private termService!: TermService
    private contactService!: ContactService


    /**
     * Initiate client instance
     * @param options Optional. Set options for HTTP requests
     */
    constructor(options?: dataSchemas.Options) {
        const defaultOptions = {
            headers: {},
            baseURL: API.baseUrl,
            version: API.version,
            timeout: API.timeout,
            responseType: 'json'
        }
        this.options = objectAssignDeep({}, defaultOptions, options)
    }

    /**
     * Configure client instance
     * @param options Optional. Set options for HTTP requests
     */
    configure = (options?: dataSchemas.Options) => {
        this.options = objectAssignDeep(this.options, options)
        this.authService = new AuthService(this.options)
        this.userService = new UserService(this.options)
        this.uploadService = new UploadService(this.options)
        this.testimonialService = new TestimonialService(this.options)
        this.faqService = new FaqService(this.options)
        this.offerService = new OfferService(this.options)
        this.stationService = new StationService(this.options)
        this.travelService = new TravelService(this.options)
        this.publicationService = new PublicationService(this.options)
        this.joinTravelRequestService = new JoinTravelRequestService(this.options)
        this.distributorService = new DistributorService(this.options)
        this.travelTicketService = new TravelTicketService(this.options)
        this.recommandationService = new RecommandationService(this.options)
        this.adviceService = new AdviceService(this.options)
        this.alerteService = new AlerteService(this.options)
        this.feedBackService = new FeedBackService(this.options)
        this.termService = new TermService(this.options)
        this.contactService = new ContactService(this.options)
    }

    /**
     * User login
     * @param request LoginRequest. Login Credentials
     */
    login = (request: dataSchemas.LoginRequest) => this.authService.login(request)

    /**
     * Register new user
     * @param request . User informations
     */
    register = (request: any) =>
        this.authService.register(request)

    /**
   * registerGoogle new user
   * @param request . User informations
   */
    registerGoogle = (request: any) =>
        this.authService.registerGoogle(request)


    /**
* registerFacebook new user
* @param request . User informations
*/
    registerFacebook = (request: any) =>
        this.authService.registerFacebook(request)



    /**
     * Password recovery request, new password sent by email
     * @param request . Email
     */
    recoverPassword = (request: any) => this.authService.recoverPassword(request)


    /**
     * Password reset request
     * @param request . Email, old password and new password
     */
    resetPassword = (request: any) =>
        this.authService.resetPassword(request)

    /**
  * Account Confirmation request  request
  * @param request . Email
  */
    accountConfirmation = (request: any) =>
        this.authService.accountConfirmation(request)



    /**
     * Change enail request, activation code  sent by email
     * @param request . Email
     */
    changeEmail = (request: any) => this.authService.changeEmail(request)

    /**
     * Get user information by token
     */
    getMe = () =>
        this.authService.getMe()

    /**
     * Get Users
     * @param request . JWT
     */
    getAllUsers = (
        typeAccount: string,
        paginationOptions: dataSchemas.PaginationWithSearchOptions = { offset: 0, limit: 10, search: '' }
    ) => this.userService.getAll(typeAccount, paginationOptions)

    /**
     * Get user by id
     * @param id, JWT
     */
    getUserById = (id: string): Promise<any> => this.userService.getById(id)

    /**
     * Update My Account information
     * user data,  JWT
     */
    updateMe = (req: any) =>
        this.userService.updateMe(req)

    /**
     * Update Users
     * @param id, user data,  JWT
     */
    updateUser = (id: string, req: any) =>
        this.userService.update(id, req)

    /**
     * Delete Users
     * @param id, JWT
     */
    deleteUser = (id: string) => this.userService.delete(id)

    /**
     * Delete Many Users
     * @param id, JWT
     */
    deleteManyUser = (ids: string[]) => this.userService.deleteMany(ids)


    /**
     * Check Available Email
     * @param email
     */
    CheckAvailableEmail = (
        email: string,
    ) => this.userService.CheckAvailableEmail(email)


    /*******************  Testimonial ************************************* */

    /**
     * getAllTestimonials
     * @param request Request params
     */
    getAllTestimonials = (request: dataSchemas.SampleRequest) =>
        this.testimonialService.getAll(request)

    /**
     * Get fiinance by id
     * @param id testimonial id
     */
    getTestimonialById = (id: string): Promise<any> => this.testimonialService.getById(id)

    /**
     * Create Testimonial
     * @param body Project body
     */
    createTestimonial = (body: dataSchemas.Testimonial): Promise<dataSchemas.Testimonial> =>
        this.testimonialService.create(body)

    /**
     * Update testimonial
     * @param id testimonial id
     * @param body testimonial body
     */
    updateTestimonial = (
        id: string,
        body: dataSchemas.Testimonial
    ): Promise<dataSchemas.Testimonial> => this.testimonialService.update(id, body)

    /**
     * delete testimonial
     * @param id testimonial id
     */
    deleteTestimonial = (id: string): Promise<dataSchemas.Testimonial> =>
        this.testimonialService.delete(id)

    /*******************  Faq ************************************* */

    /**
     * getAllFaqs
     * @param request Request params
     */
    getAllFaqs = (request: dataSchemas.SampleRequest) =>
        this.faqService.getAll(request)

    /**
     * Get fiinance by id
     * @param id faq id
     */
    getFaqById = (id: string): Promise<any> => this.faqService.getById(id)

    /**
     * Create Faq
     * @param body Project body
     */
    createFaq = (body: dataSchemas.Faq): Promise<dataSchemas.Faq> =>
        this.faqService.create(body)

    /**
     * Update faq
     * @param id faq id
     * @param body faq body
     */
    updateFaq = (
        id: string,
        body: dataSchemas.Faq
    ): Promise<dataSchemas.Faq> => this.faqService.update(id, body)

    /**
     * delete faq
     * @param id faq id
     */
    deleteFaq = (id: string): Promise<dataSchemas.Faq> =>
        this.faqService.delete(id)


    /*******************  Station ************************************* */

    /**
     * getAllStations
     * @param request Request params
     */
    getAllStations = (request: dataSchemas.SampleRequest) =>
        this.stationService.getAll(request)
    /**
 * Get station by id
 * @param id station id
 */
    getStationById = (id: string): Promise<any> => this.stationService.getById(id)

    /**
     * Create Station
     * @param body Project body
     */
    createStation = (body: dataSchemas.Station): Promise<dataSchemas.Station> =>
        this.stationService.create(body)

    /**
     * Create Station
     * @param body Project body
     */
    resetStation = (): Promise<any> => this.stationService.reset()

    /**
     * Update Station
     * @param id Station id
     * @param body Station body
     */
    updateStation = (
        id: string,
        body: dataSchemas.Station
    ): Promise<dataSchemas.Station> => this.stationService.update(id, body)


    /**
 * getAllStations
 * @param request Request params
 */
    getAllCities = (request: dataSchemas.SampleRequest) =>
        this.stationService.getAllCities(request)

    /*******************  Offer ************************************* */
    /**
     * getAllOffers
     * @param request Request params
     */
    getAllOffers = (request: dataSchemas.SampleRequest) =>
        this.offerService.getAll(request)

    /**
     * Get alerte by id
     * @param id alerte id
     */
    getOfferById = (id: string): Promise<any> => this.offerService.getById(id)


    /**
     * Create Offer
     * @param body Project body
     */
    createOffer = (body: dataSchemas.Offer): Promise<dataSchemas.Offer> =>
        this.offerService.create(body)

    /**
     * Update offer
     * @param id offer id
     * @param body offer body
     */
    updateOffer = (
        id: string,
        body: dataSchemas.Offer
    ): Promise<dataSchemas.Offer> => this.offerService.update(id, body)

    /**
     * delete offer
     * @param id offer id
     */
    deleteOffer = (id: string): Promise<dataSchemas.Offer> =>
        this.offerService.delete(id)

    /*******************  Alerte ************************************* */

    /**
     * getAllAlertes
     * @param request Request params
     */
    getAllAlertes = (request: dataSchemas.SampleRequest) =>
        this.alerteService.getAll(request)

    /**
     * Get alerte by id
     * @param id alerte id
     */
    getAlerteById = (id: string): Promise<any> => this.alerteService.getById(id)

    /**
     * Create Alerte
     * @param body Project body
     */
    createAlerte = (body: dataSchemas.Alerte): Promise<dataSchemas.Alerte> =>
        this.alerteService.create(body)

    /**
     * Update alerte
     * @param id alerte id
     * @param body alerte body
     */
    updateAlerte = (
        id: string,
        body: dataSchemas.Alerte
    ): Promise<dataSchemas.Alerte> => this.alerteService.update(id, body)

    /**
     * delete alerte
     * @param id alerte id
     */
    deleteAlerte = (id: string): Promise<dataSchemas.Alerte> =>
        this.alerteService.delete(id)

    /*******************  Travel ************************************* */

    /**
     * getAllTravels
     * @param request Request params
     */
    getAllTravels = (request: dataSchemas.TravelPagination) =>
        this.travelService.getAll(request)

    /**
     * getAllTravelsFilterByCurentDay
     * @param request Request params
     */
    getAllTravelsFilterByCurentDay = (request: dataSchemas.TravelPagination) =>
        this.travelService.getAllFilterByCurentDay(request)

    /**
    * getAllSecuredFilterByCurentDay
    * @param request Request params
    */
    getAllSecuredFilterByCurentDay = (request: dataSchemas.TravelPagination) =>
        this.travelService.getAllSecuredFilterByCurentDay(request)

    /**
     * Get travel by id
     * @param id travel id
     */
    getTravelById = (id: string): Promise<any> => this.travelService.getById(id)

    /**
     * Get travel by id for authenticate user
     * @param id travel id
     */
    getSecuredTravelById = (id: string): Promise<any> => this.travelService.getSecuredById(id)

    /**
 * view trip messages by id
 * @param id travel id
 */
    getViewTripMessaesById = (id: any): Promise<any> => this.travelService.viewMessage(id)



    /**
     * Add message to trip chat
     * @param id alerte id
     * @param body alerte body
     */
    addMessageTripChat = (
        id: any,
        body: dataSchemas.Message
    ): Promise<any> => this.travelService.addMessage(id, body)

    /**
 * delete chat message 
 * @param id  id chat
 * @param idMessage message id
 */
    deleteMessageTripChat = (
        id: any,
        idMessages: string[],
    ): Promise<any> => this.travelService.deleteMessage(id, idMessages)


    /**
     * Get travel by id
     * @param id travel id
     */
    getUserTravelById = (id: string, paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getUserTravelById(id, paginationWithSearchOption)

    /**
    * Get travel by id
    * @param id travel id
    */
    getUserTravelSecuredById = (id: string, paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getUserTravelSecuredById(id, paginationWithSearchOption)


    /**
* Get General Statistique
*/
    getGeneralStatitique = (): Promise<any> => this.travelService.getGeneralStatitique()


    /**
* Get trip actif state General Statistique
*/
    getTripsActifsState = (): Promise<any> => this.travelService.getTripsActifsState()


    /**
* Get General Activitz Statistique
*/
    getActivityStatitique = (paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getActivityStatitique(paginationWithSearchOption)




    /**
    * Getuser travel
    */
    getUserTravel = (paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getUserTravel(paginationWithSearchOption)

    /**
    * Get user old travels
    */
    getUserOldTravel = (paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getUserOldTravel(paginationWithSearchOption)


    /**
* Get user travels
*/
    getUserNewTravel = (paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getUserNewTravel(paginationWithSearchOption)



    /**
* Get joined travel by id
* @param id travel id
*/
    getUserJoinedTravel = (paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getUserJoinedTravel(paginationWithSearchOption)


    /**
* Get my travel statistique
*/
    getUserTravelStatitique = (): Promise<any> => this.travelService.getUserTravelStatitique()

    /**
* Get created and joined travel
*/
    getUserCreatedAndJoinedTravel = (): Promise<any> => this.travelService.getUserCreatedAndJoinedTravel()




    /**
* Get pending travel by id
* @param id travel id
*/
    getUserPendingTravel = (paginationWithSearchOption: dataSchemas.TravelPagination): Promise<any> => this.travelService.getUserPendingTravel(paginationWithSearchOption)


    /**
* Get My Frequest Station
*/
    getFrequentStation = (): Promise<any> => this.travelService.getFrequentStation()

    /**
* Get My Frequest Distributor
*/

    getFrequentDistributor = (): Promise<any> => this.travelService.getFrequentDistributor()

    /**
* Get My Frequest Ticket
*/
    getFrequentTicket = (): Promise<any> => this.travelService.getFrequentTicket()


    /**
     * Create Travel
     * @param body Project body
     */
    createTravel = (body: dataSchemas.Travel): Promise<dataSchemas.Travel> =>
        this.travelService.create(body)

    /**
     * Join Travel by travel id 
     * @param body Project body
     */
    joinTravel = (id: string): Promise<any> =>
        this.travelService.joinTravel(id)


    /**
    * Accept Join Travel by jointravelrequest  id 
    * @param body Project body
    */
    acceptJoinTravel = (id: string): Promise<any> =>
        this.travelService.acceptJoinTravel(id)

    /**
    * Remove participant from a trip 
    * @param body Project body
    */
    removeJoinTravel = (id: string, participantId: string): Promise<any> =>
        this.travelService.removeJoinTravel(id, participantId)

    /**
     * 
    * Refuse Join Travel by jointravelrequest  id 
    * @param body Project body
    */
    refuseJoinTravel = (id: string): Promise<any> =>
        this.travelService.refuseJoinTravel(id)

    /**
     * leave Travel by travel id 
     * @param body Project body
     */
    leaveTravel = (id: string): Promise<any> =>
        this.travelService.leaveTravel(id)

    /**
     * delete my Travel by travel id 
     * @param body Project body
     */
    deleteMyTravel = (id: string): Promise<any> =>
        this.travelService.deleteMyTravel(id)

    /**
     * Update travel
     * @param id travel id
     * @param body travel body
     */
    updateTravel = (
        id: string,
        body: dataSchemas.Travel
    ): Promise<dataSchemas.Travel> => this.travelService.update(id, body)


    /**
     * Delete Many Users
     * @param id, JWT
     */
    deleteManyTravel = (ids: string[]) => this.travelService.deleteMany(ids)


    /*******************  Distributor ************************************* */

    /**
     * getAllDistributors
     * @param request Request params
     */
    getAllDistributors = (request: dataSchemas.DistributorPaginationOptions) =>
        this.distributorService.getAll(request)

    /**
     * Get distributor by id
     * @param id distributor id
     */
    getDistributorById = (id: string): Promise<any> => this.distributorService.getById(id)

    /**
     * Create Distributor
     * @param body Project body
     */
    createDistributor = (body: dataSchemas.Distributor): Promise<dataSchemas.Distributor> =>
        this.distributorService.create(body)

    /**
     * Update distributor
     * @param id distributor id
     * @param body distributor body
     */
    updateDistributor = (
        id: string,
        body: dataSchemas.Distributor
    ): Promise<dataSchemas.Distributor> => this.distributorService.update(id, body)


    /**
     * Delete Many Users
     * @param id, JWT
     */
    deleteManyDistributor = (ids: string[]) => this.distributorService.deleteMany(ids)

    /*******************  TravelTicket ************************************* */

    /**
     * getAllTravelTickets
     * @param request Request params
     */
    getAllTravelTickets = (request: dataSchemas.TravelTicketPaginationOptions) =>
        this.travelTicketService.getAll(request)

    /**
     * Get travelTicket by id
     * @param id travelTicket id
     */
    getTravelTicketById = (id: string): Promise<any> => this.travelTicketService.getById(id)

    /**
     * Create TravelTicket
     * @param body Project body
     */
    createTravelTicket = (body: dataSchemas.TravelTicket): Promise<dataSchemas.TravelTicket> =>
        this.travelTicketService.create(body)

    /**
     * Update travelTicket
     * @param id travelTicket id
     * @param body travelTicket body
     */
    updateTravelTicket = (
        id: string,
        body: dataSchemas.TravelTicket
    ): Promise<dataSchemas.TravelTicket> => this.travelTicketService.update(id, body)


    /**
     * Delete Many Users
     * @param id, JWT
     */
    deleteManyTravelTicket = (ids: string[]) => this.travelTicketService.deleteMany(ids)


    /*******************  Publication ************************************* */

    /**
     * getAllPublications
     * @param request Request params
     */
    getAllPublications = (request: dataSchemas.SampleRequest) =>
        this.publicationService.getAll(request)

    /**
     * Get publication by id
     * @param id publication id
     */
    getPublicationById = (id: string): Promise<any> => this.publicationService.getById(id)

    /**
     * Create Publication
     * @param body Project body
     */
    createPublication = (body: dataSchemas.Publication): Promise<dataSchemas.Publication> =>
        this.publicationService.create(body)

    /**
     * Update publication
     * @param id publication id
     * @param body publication body
     */
    updatePublication = (
        id: string,
        body: dataSchemas.Publication
    ): Promise<dataSchemas.Publication> => this.publicationService.update(id, body)

    /**
     * delete publication
     * @param id publication id
     */
    deletePublication = (id: string): Promise<dataSchemas.Publication> =>
        this.publicationService.delete(id)

    /*******************  Advice ************************************* */

    /**
     * getAllAdviceTickets
     * @param request Request params
     */
    getAllAdvices = (request: dataSchemas.AdvicePagination) =>
        this.adviceService.getAll(request)


    /**
     * getAllAdviceTickets
     * @param request Request params
     */
    getFullAdvices = () =>
        this.adviceService.getFull()

    /**
     * Get advice by id
     * @param id advice id
     */
    getAdviceById = (id: string): Promise<any> => this.adviceService.getById(id)

    /**
     * Create Advice
     * @param body advice body
     */
    createAdvice = (body: dataSchemas.Advice): Promise<dataSchemas.Advice> =>
        this.adviceService.create(body)

    /**
     * Update advice
     * @param id advice id
     * @param body advice body
     */
    updateAdvice = (
        id: string,
        body: dataSchemas.Advice
    ): Promise<dataSchemas.Advice> => this.adviceService.update(id, body)


    /**
     * Delete Many Users
     * @param id, JWT
     */
    deleteManyAdvice = (ids: string[]) => this.adviceService.deleteMany(ids)

    /*******************  RecommandationTicket ************************************* */

    /**
     * getAllRecommandationTickets
     * @param request Request params
     */
    getAllRecommandations = (request: dataSchemas.RecommandationPagination) =>
        this.recommandationService.getAll(request)

    /**
     * Get recommandation by id
     * @param id recommandation id
     */
    getRecommandationById = (id: string): Promise<any> => this.recommandationService.getById(id)

    /**
     * Create Recommandation
     * @param body recommandation body
     */
    createRecommandation = (body: dataSchemas.Recommandation): Promise<dataSchemas.Recommandation> =>
        this.recommandationService.create(body)

    /**
     * Update recommandation
     * @param id recommandation id
     * @param body recommandation body
     */
    updateRecommandation = (
        id: string,
        body: dataSchemas.Recommandation
    ): Promise<dataSchemas.Recommandation> => this.recommandationService.update(id, body)


    /**
     * Delete Many Users
     * @param id, JWT
     */
    deleteManyRecommandation = (ids: string[]) => this.recommandationService.deleteMany(ids)


    /******************* FeedBack ************************************* */

    /**
     * getAllFeedBacks
     * @param request Request params
     */
    getAllFeedBacks = (request: dataSchemas.SampleRequest) =>
        this.feedBackService.getAll(request)

    /**
    * Create FeedBack
    * @param body Project body
    */
    createFeedBack = (body: dataSchemas.FeedBack): Promise<dataSchemas.FeedBack> =>
        this.feedBackService.create(body)



    /******************* Contact ************************************* */

    /**
     * getAllContacts
     * @param request Request params
     */
    getAllContacts = (request: dataSchemas.SampleRequest) =>
        this.contactService.getAll(request)

    /**
    * Create Contact
    * @param body Project body
    */
    createContact = (body: dataSchemas.Contact): Promise<dataSchemas.Contact> =>
        this.contactService.create(body)



    /*******************  JoinTravelRequest ************************************* */

    /**
     * getAllJoinTravelRequests
     * @param request Request params
     */
    getAllJoinTravelRequests = (request: dataSchemas.SampleRequest) =>
        this.joinTravelRequestService.getAll(request)

    /**
     * Get joinTravelRequest by id
     * @param id joinTravelRequest id
     */
    getJoinTravelRequestById = (id: string): Promise<any> => this.joinTravelRequestService.getById(id)

    /**
     * Create JoinTravelRequest
     * @param body Project body
     */
    createJoinTravelRequest = (body: dataSchemas.JoinTravelRequest): Promise<dataSchemas.JoinTravelRequest> =>
        this.joinTravelRequestService.create(body)

    /**
     * Update joinTravelRequest
     * @param id joinTravelRequest id
     * @param body joinTravelRequest body
     */
    updateJoinTravelRequest = (
        id: string,
        body: dataSchemas.JoinTravelRequest
    ): Promise<dataSchemas.JoinTravelRequest> => this.joinTravelRequestService.update(id, body)

    /**
     * delete joinTravelRequest
     * @param id joinTravelRequest id
     */
    deleteJoinTravelRequest = (id: string): Promise<dataSchemas.JoinTravelRequest> =>
        this.joinTravelRequestService.delete(id)

    /**
      * Upload file
      * @param request File
      */
    uploadFile = (file: any) => this.uploadService.upload(file)

    /**
     * Get uploaded file
     * @param request filename String
     */
    getFile = (filename: string) => this.uploadService.get(filename)

    /**
     * Delete uploaded file
     * @param request filename String
     */
    deleteFile = (filename: string) => this.uploadService.delete(filename)


    /*******************  Term ************************************* */

    /**
    * getAllTerms
    * @param request Request params
    */
    getAllTerms = (request: dataSchemas.SampleRequest) =>
        this.termService.getAll(request)

    /**
     * Get fiinance by id
     * @param id term id
     */
    getTermById = (id: string): Promise<any> => this.termService.getById(id)

    /**
     * Create Term
     * @param body Project body
     */
    createTerm = (body: dataSchemas.Term): Promise<dataSchemas.Term> =>
        this.termService.create(body)

    /**
     * Update term
     * @param id term id
     * @param body term body
     */
    updateTerm = (
        id: string,
        body: dataSchemas.Term
    ): Promise<dataSchemas.Term> => this.termService.update(id, body)

    /**
     * delete term
     * @param id term id
     */
    deleteTerm = (id: string): Promise<dataSchemas.Term> =>
        this.termService.delete(id)

    /**
     * Delete Many Users
     * @param id, JWT
     */
    deleteManyTerm = (ids: string[]) => this.termService.deleteMany(ids)


}

export * as types from './utils/DataSchemas'
