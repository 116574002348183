import Box, { BoxProps } from "@material-ui/core/Box";
import LogoPic from "../assets/logosb.png"

type LogoProps = {
  width?:number,
  colored?: boolean;
  size?: number;
} & BoxProps;

const Logo = ({ width = 130, colored = false, size = 130, ...boxProps }: LogoProps) => {
  return (
    <Box {...boxProps}>
      <img width={width} alt="logo" src={LogoPic}/>
    </Box>
  );
};

export default Logo;
