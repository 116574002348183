import { Options, Publication, PaginationOptions } from '../utils/DataSchemas'
import request from '../utils/request'

export default class PublicationService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (paginationWithSearchOption: PaginationOptions) => {
    let query = ''

    if (paginationWithSearchOption.offset !== null) {
      query
        ? (query += `&offset=${paginationWithSearchOption.offset}`)
        : (query += `?offset=${paginationWithSearchOption.offset}`)
    }

    if (paginationWithSearchOption.limit) {
      query
        ? (query += `&limit=${paginationWithSearchOption.limit}`)
        : (query += `?limit=${paginationWithSearchOption.limit}`)
    }
    if (paginationWithSearchOption.search) {
      query
        ? (query += `&search=${paginationWithSearchOption.search}`)
        : (query += `?search=${paginationWithSearchOption.search}`)
    }

    return request('GET', `publications/${query}`, this.options)
  }

  async create(data: Publication) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `publications/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `publications/${id}`, this.options)
  }

  async update(id: string, data: Publication) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PATCH', `publications/${id}`, requestOptions)
  }

  async delete(id: string) {
    return request('DELETE', `publications/${id}`, this.options)
  }
}
