import { Message, Options, Travel, TravelPagination } from '../utils/DataSchemas'
import request from '../utils/request'

export default class TravelService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getQuery = (paginationWithSearchOption: TravelPagination) => {
    let query = ''

    if (paginationWithSearchOption.offset !== null) {
      query
        ? (query += `&offset=${paginationWithSearchOption.offset}`)
        : (query += `?offset=${paginationWithSearchOption.offset}`)
    }

    if (paginationWithSearchOption.limit) {
      query
        ? (query += `&limit=${paginationWithSearchOption.limit}`)
        : (query += `?limit=${paginationWithSearchOption.limit}`)
    }
    if (paginationWithSearchOption.search) {
      query
        ? (query += `&search=${paginationWithSearchOption.search}`)
        : (query += `?search=${paginationWithSearchOption.search}`)
    }
    if (paginationWithSearchOption.arrival) {
      query
        ? (query += `&arrival=${paginationWithSearchOption.arrival}`)
        : (query += `?arrival=${paginationWithSearchOption.arrival}`)
    }
    if (paginationWithSearchOption.departure) {
      query
        ? (query += `&departure=${paginationWithSearchOption.departure}`)
        : (query += `?departure=${paginationWithSearchOption.departure}`)
    }
    if (paginationWithSearchOption.departureTime) {
      query
        ? (query += `&departureTime=${paginationWithSearchOption.departureTime}`)
        : (query += `?departureTime=${paginationWithSearchOption.departureTime}`)
    }
    if (paginationWithSearchOption.creator) {
      query
        ? (query += `&creator=${paginationWithSearchOption.creator}`)
        : (query += `?creator=${paginationWithSearchOption.creator}`)
    }
    if (paginationWithSearchOption.filterToday) {
      query
        ? (query += `&filterToday=${paginationWithSearchOption.filterToday}`)
        : (query += `?filterToday=${paginationWithSearchOption.filterToday}`)
    }
    if (paginationWithSearchOption.type) {
      query
        ? (query += `&type=${paginationWithSearchOption.type}`)
        : (query += `?type=${paginationWithSearchOption.type}`)
    }
    if (paginationWithSearchOption.filterDate) {
      query
        ? (query += `&filterDate=${paginationWithSearchOption.filterDate}`)
        : (query += `?filterDate=${paginationWithSearchOption.filterDate}`)
    }
    if (paginationWithSearchOption.filterChatNumber) {
      query
        ? (query += `&filterChatNumber=${paginationWithSearchOption.filterChatNumber}`)
        : (query += `?filterChatNumber=${paginationWithSearchOption.filterChatNumber}`)
    }
    return query
  }

  getStatQuery = (paginationWithSearchOption: TravelPagination) => {
    let query = ''

    if (paginationWithSearchOption.from) {
      query
        ? (query += `&from=${paginationWithSearchOption.from}`)
        : (query += `?from=${paginationWithSearchOption.from}`)
    }

    if (paginationWithSearchOption.to) {
      query
        ? (query += `&to=${paginationWithSearchOption.to}`)
        : (query += `?to=${paginationWithSearchOption.to}`)
    }
    if (paginationWithSearchOption.type) {
      query
        ? (query += `&type=${paginationWithSearchOption.type}`)
        : (query += `?type=${paginationWithSearchOption.type}`)
    }
    return query
  }
  getAll = async (paginationWithSearchOption: TravelPagination) => {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/query${query}`, this.options)
  }

  getAllFilterByCurentDay = async (paginationWithSearchOption: TravelPagination) => {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/filter-by-current-day/query${query}`, this.options)
  }


  getAllSecuredFilterByCurentDay = async (paginationWithSearchOption: TravelPagination) => {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/user/filter-by-current-day/query${query}`, this.options)
  }

  async create(data: Travel) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `travels/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `travels/info/${id}`, this.options)
  }

  async getSecuredById(id: string) {
    return request('GET', `travels/secured/info/${id}`, this.options)
  } 
  
  async getTripsActifsState() {
    return request('GET', `travels/statistique/actif/trip`, this.options)
  }

  async joinTravel(id: string) {
    return request('POST', `travels/join/${id}`, this.options)
  }

  async leaveTravel(id: string) {
    return request('POST', `travels/leave/${id}`, this.options)
  }

  async acceptJoinTravel(id: string) {
    return request('POST', `travels/join/accept/${id}`, this.options)
  }

  async removeJoinTravel(id: string,participantId:string) {
    return request('POST', `travels/${id}/join/remove/${participantId}`, this.options)
  }

  async refuseJoinTravel(id: string) {
    return request('POST', `travels/join/refuse/${id}`, this.options)
  }

  async getUserTravelById(id: string, paginationWithSearchOption: TravelPagination) {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/user/${id}${query}`, this.options)
  }

  async getUserTravelSecuredById(id: string, paginationWithSearchOption: TravelPagination) {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/secured-user/${id}${query}`, this.options)
  }
  async getGeneralStatitique() {
    return request('GET', `travels/statistique`, this.options)
  }

  async getActivityStatitique(paginationWithSearchOption: TravelPagination) {
    let query = this.getStatQuery(paginationWithSearchOption)
    return request('GET', `travels/statistique/trip${query}`, this.options)
  }

  async getUserTravel(paginationWithSearchOption: TravelPagination) {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/my-travels${query}`, this.options)
  }

  async getUserNewTravel(paginationWithSearchOption: TravelPagination) {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/my-new-travels${query}`, this.options)
  }

  async getUserOldTravel(paginationWithSearchOption: TravelPagination) {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/my-old-travels${query}`, this.options)
  }


  async getUserJoinedTravel(paginationWithSearchOption: TravelPagination) {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/my-joined-travels${query}`, this.options)
  }
  async getUserCreatedAndJoinedTravel() {
    return request('GET', `travels/my/created-joined-trips`, this.options)
  }

  async getUserPendingTravel(paginationWithSearchOption: TravelPagination) {
    let query = this.getQuery(paginationWithSearchOption)
    return request('GET', `travels/my-pending-travels${query}`, this.options)
  }

  async getUserTravelStatitique() {
    return request('GET', `travels/my-travels-statistique`, this.options)
  }

  async getFrequentStation() {
    return request('GET', `travels/frequent/station`, this.options)
  }

  async getFrequentTicket() {
    return request('GET', `travels/frequent/ticket`, this.options)
  }

  async getFrequentDistributor() {
    return request('GET', `travels/frequent/distributor`, this.options)
  }

  async getFrequentD() {
    return request('GET', `travels/frequent/distributor`, this.options)
  }

  async deleteMyTravel(id: string) {
    return request('DELETE', `travels/delete/${id}`, this.options)
  }

  async update(id: string, data: Travel) {
    const requestOptions = {
      ...this.options,
      data: data
    }
    return request('PATCH', `travels/${id}`, requestOptions)
  }

  async delete(id: string) {
    return request('DELETE', `travels/${id}`, this.options)
  }

  deleteMany = async (ids: string[]) => {
    const requestOptions: Options = {
      ...this.options,
      data: ids
    }

    return request('DELETE', 'travels/multiple', requestOptions)
  }

  async addMessage(id: any, data: Message) {
    const requestOptions = {
      ...this.options,
      data: data
    }
    return request('POST', `travels/message/add/${id}`, requestOptions)
  }

  async deleteMessage(id: any, idMessages: string[]) {
    const requestOptions: Options = {
      ...this.options,
      data: idMessages
    }
    return request('DELETE', `travels/message/delete/${id}`, requestOptions)
  }

  async viewMessage(id: any) {
    return request('GET', `travels/message/view/${id}`, this.options)
  }
}
