import { AuthActionTypeEnum } from "../../helpers/enums/statActionTypeEnums";
import { Auth } from '../types/auth'
import { User } from "../../Views/users/types/user";


export interface State {
    userData: Auth|null,
    access_token: string|null,
    selectUser:User|null
}

const initialState: State = {
    userData: null,
    access_token: null,
    selectUser:null,
}

  
const AuthReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AuthActionTypeEnum.LOGIN: {
          return {
            ...state,
            userData: action.data,
            access_token: action.data?.access_token
          }
        }
        case AuthActionTypeEnum.UPDATE_SELECT_USER: {
          return {
            ...state,
            selectUser: action.selectUser
          }
        }
        case AuthActionTypeEnum.LOGOUT: {
            return {
              ...state,
              userData: null,
              access_token: null
            }
          }
    
        default:
          return state;
    }
};

export default AuthReducer;