import { Options, Recommandation , RecommandationPagination } from '../utils/DataSchemas'
import request from '../utils/request'

export default class RecommandationService {
  private options: Options

  constructor(options: Options) {
    this.options = options
  }

  getAll = async (paginationWithSearchOption: RecommandationPagination) => {
    let query = ''

    if (paginationWithSearchOption.offset !== null) {
      query
        ? (query += `&offset=${paginationWithSearchOption.offset}`)
        : (query += `?offset=${paginationWithSearchOption.offset}`)
    }

    if (paginationWithSearchOption.limit) {
      query
        ? (query += `&limit=${paginationWithSearchOption.limit}`)
        : (query += `?limit=${paginationWithSearchOption.limit}`)
    }
    if (paginationWithSearchOption.search) {
      query
        ? (query += `&search=${paginationWithSearchOption.search}`)
        : (query += `?search=${paginationWithSearchOption.search}`)
    }

    if (paginationWithSearchOption.isAds) {
        query
          ? (query += `&isAds=${paginationWithSearchOption.isAds}`)
          : (query += `?isAds=${paginationWithSearchOption.isAds}`)
      }
  

    return request('GET', `recommandations${query}`, this.options)
  }

  async create(data: Recommandation) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    
    return request('POST', `recommandations/`, requestOptions)
  }

  async getById(id: string) {
    return request('GET', `recommandations/${id}`, this.options)
  }



  async update(id: string, data: Recommandation) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PATCH', `recommandations/${id}`, requestOptions)
  }


  deleteMany = async (ids: string[]) => {
    const requestOptions: Options = {
      ...this.options,
      data:ids
    }
    
    return request('DELETE', 'recommandations/multiple', requestOptions)
  }
}
