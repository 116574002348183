import { Distributor } from '../../types/distributor'
import { ActionType } from '../../../../helpers/types/ActionType';
import { DistributorActionTypeEnum } from '../../helpers/enums/actionTypeEnums';
import { updatedArray, arrayReduceMany } from '../../../../helpers/functions';

export interface State {
  value: number,
  data: Distributor[],
  total: number
}

const initialState: State = {
  value: 0,
  data:[],
  total: 0
}

export const GetData = (distributor: Distributor | any) => {

  const dataInfo = {
    ...distributor,
    key: distributor.id??distributor?._id,
    id: distributor.id??distributor?._id,
  }
  return dataInfo
}
const DataListFormater = (data: Distributor[] | any) => {
  const results = [{}]
  results.pop()
  if (data) {
    data.map((dataInfo: any) => {
      results.push(DataFormat(dataInfo))
    })
  }
  return results
}

const DataFormat = (distributorData: Distributor | any) => {
  const data = GetData(distributorData)
  return {
    ...data
  }
}

const DistributorReducer = (state = initialState, action: ActionType<Distributor>) => {
  switch (action.type) {
    case DistributorActionTypeEnum.List: {
      return {
        ...state,
        data: DataListFormater(action.results),
        total: action.total
      }
    }

    case DistributorActionTypeEnum.Update:
      const updatedData = DataFormat(action.data);
      return {
        ...state,
        data: updatedArray(state.data, updatedData),
        showModal: false
      };

    case DistributorActionTypeEnum.Delete:
      const ids = action.ids||[];
      return {
        ...state,
        data: arrayReduceMany(state.data, ids),
        total: state.total - ids?.length,
        showModal: false
      };

    case DistributorActionTypeEnum.Add:
      let dataList = !state.data ? [] : state.data;
      dataList.unshift(DataFormat(action.data) as Distributor);
      return {
        ...state,
        data: dataList,
        total: state.total + 1,
        showModal: false
      };

    default:

      return state;
  }
};

export default DistributorReducer;