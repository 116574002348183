export const updatedArray = (array: any, element: any) => {
    let index = 0;
    for (let i = 0; i < array.length; i++) {
        if (array[i].key === element.key) {
            array[i] = element;
            index = i;
            break;
        }
    }
    return array;
};

export const arrayReduce = (array: any, element: any) => {
    let tab = array;
    const index = tab.indexOf(element);
    if (index > -1) {
        tab.splice(index, 1);
    }
    return tab;
};

export const arrayReduceMany = (array: any, keys: any) => {
    let tab = [...array];
    for (let i = 0; i < array.length; i++) {
        if (keys.includes(array[i].key)) {
            tab = arrayReduce(tab, array[i])
        }
    }
    return tab;
};
