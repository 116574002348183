interface API {
  baseUrl: string | undefined
  timeout: number
  version: string
}

const API: API = {
  baseUrl: process.env.REACT_APP_API_URL ?? process.env.NEXT_PUBLIC_API_URL,
  timeout: 30000,
  version: 'v1'
}

export default API
